//Lazy load
new LazyLoad({
    // Your custom settings go here
});
//Animations
const wow = new WOW(
    {
        mobile: false,       // trigger animations on mobile devices (default is true)
    }
);
wow.init();
$(document).ready(function () {
    //Scroll to
    const scrollTo = (elem,attr) => {
        $(elem).on("click", function () {
            let anchor = $(this).attr(attr);
            $('html, body').stop().animate({
                scrollTop: $(anchor).offset().top
            }, 1500);
        });
    };
    if($('.scrollTo').length) {
        scrollTo('.scrollTo','href')
    }
    //Resize helper
    const resizeHelper = (width,callback) =>{
        if ($(window).width() <= width) {
            callback()
        }
        $(window).resize(function () {
            if ($(window).width() <= width) {
                callback()
            }
        });
    }
    // Слайдер с клиентами
    if($('.clients__slider').length) {
        $('.clients__slider').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 1000,
            infinite: true,
            dots: false,
            arrows: false,
            centerMode: true,
            centerPadding: '65px',
            adaptiveHeight: true,
            variableWidth: true,

        });
    }
    //Слайдер с командой
    if($('.wir__slider').length) {
        $('.wir__slider').slick({
            slidesToShow: 5,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            arrows: true,
            appendArrows: '.slider-arrows',
            prevArrow: '<span class="slider__arrow-left"></span>',
            nextArrow: '<span class="slider__arrow-right"></span>',
            centerMode: false,
            responsive: [
                {
                    breakpoint: 3000,
                    settings: {
                        slidesToShow: 6,
                    }
                },

                {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 4.2,
                }
            },
                {
                    breakpoint: 769,
                    settings: {
                        slidesToShow: 2.2,
                    }
                },
                {
                    breakpoint: 680,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]

        });
    }
    //слайдер с видео
    if($('.inner-slider').length){
        $('.inner-slider').slick({
            arrows: true,
            appendArrows: '.slider-arrows',
            prevArrow: '<div class="slider__arrow-left-container"><span class="slider__arrow-left"></span></div>',
            nextArrow: '<div class="slider__arrow-right-container"><span class="slider__arrow-right"></span></div>',
            slidesToShow: 3,
            slidesToScroll: 1,
            centerMode: true,
            // adaptiveHeight: true,
            infinite: true,
            draggable: true,
            responsive: [
                {
                  breakpoint: 635,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    vertical: true,
                    verticalSwiping: true,
                    centerMode: true,

                  }
                },
            ]
        })
    }


    //Валидатор формы
    if($('.contact-form').length) {
        $('.contact-form').validate({
            //debug чтобы форма не отправлялась
            debug: false,
            //добавляем правила для отдельных инпутов, селектор по имени
            rules: {
                name: {
                    required: true,
                    minlength: 2,
                    lettersonly: true
                },
                email: {
                    required: true,
                    email: true,
                    minlength: 5
                },
                phone: {
                    required: true,
                    minlength: 5,
                    number: true
                },
                checkbox: {
                    required: true,
                }

            },

            highlight: function (element, errorClass) {
                $(element).add($(element).parents('.input-wrapper')).addClass("invalidElem");
            },
            unhighlight: function (element, errorClass) {
                $(element).add($(element).parents('.input-wrapper')).removeClass("invalidElem");
            },



            //добавляем див с классом input-error при неправильном вводе
            errorElement: "span",
            errorClass: "input-error"
        });
    }
    //Проверка инпутов при изменении
    $('input').change(function (e) {
        $('form').validate().element($(e.target));
    });
    //добавляем в валидатор проверку lettersonly
    $.validator.addMethod("lettersonly", function (value, element) {
        return this.optional(element) || /^[a-z]+$/i.test(value);
    }, "Letters only please");

    //Изменение размера textarea при наборе текста
    var textarea = document.querySelector('textarea');

    textarea.addEventListener('input', function () {
        //если появляется необходимость скролла
        if (this.scrollTop > 0) {
            //добавляем скролл справа
            this.style.overflow = "auto";
            //увеличиваем высоту textarea
            this.style.height = this.scrollHeight + "px";
        }
    });

    //Уменьшение textarea до начальных размеров при потере фокуса и если в нем находится одна строка текста
    textarea.addEventListener('blur', function () {
        if (this.value.length <= 55) {
            this.style.height = 'auto';
        }
    })
    //mapbox
    if($('#map').length) {
        //здесь необходимо вставить свой токен с ЛК сайта mapbox
        mapboxgl.accessToken =
            'pk.eyJ1IjoibGVubnlyaXZhcyIsImEiOiJja2w1a3c0eHUwYTRnMndudTU3aTZpemtkIn0.AP7AIFScUsBk70_c9JQEFw';
        //инициализация карты
        var map = new mapboxgl.Map({
            container: 'map',
            style: 'mapbox://styles/mapbox/streets-v11',
            language: 'de',
            center: [13.3405, 52.4985],
            zoom: 11.5
        });

        //координаты для маркера
        var geojson = {
            type: 'Pin',
            features: [{
                type: 'Pin',
                geometry: {
                    type: 'Pin',
                    coordinates: [13.3496, 52.4823]
                },
                properties: {
                    title: 'EugeniushFilms',
                    description: 'EugeniushFilms Studio'
                }
            }]
        };

        // add markers to map
        geojson.features.forEach(function (marker) {

            // create a HTML element for each feature
            var el = document.createElement('div');
            el.className = 'marker';

            // make a marker for each feature and add to the map
            new mapboxgl.Marker(el)
                .setLngLat(marker.geometry.coordinates)
                .addTo(map);
        });
    }
    //Видеофон и замена постера при разных разрешениях.
    if($('#background-video').length) {
        // Смена видео,если media css не поддерживаются
        class VideoResponser {
            constructor(selector) {
                const $video = document.querySelector(selector);
                this.options = {
                    selector,
                    breakpoints: { default: { src: $video.getAttribute('data-src') } }
                };

                // get a list of video switching points and links to the videos themselves
                $video.querySelectorAll('[data-src]').forEach(element => this.options.breakpoints[element.getAttribute('data-mw')] = { src: element.getAttribute('data-src') });
                $video.innerHTML = ''; // we clean up so that there is nothing superfluous

                // run the handler and track the change in screen width
                this.responseVideo(this.options);
                this.resizer();
            }

            /** Function runs on resize  */
            resizer() {
                window.addEventListener("resize", () => this.responseVideo(this.options));
            }

            /**
             * Change src value of video link to fit screen width
             *
             * @param {Object} options object with options
             */
            responseVideo(options) {
                const {selector, breakpoints} = options; // get options
                let $video = document.querySelector(selector);
                const widthNow = $video.getAttribute('data-width-now') || null;
                const maxBreakpoint = Math.max(Object.keys(breakpoints).filter(key => key >= document.body.clientWidth)[0]);
                const nowBreakpoint = maxBreakpoint || 'default'; // choose either the maximum value, if not, then the default

                if(widthNow && widthNow == nowBreakpoint) return; // check if the video needs to be changed
                $video.setAttribute('data-width-now', nowBreakpoint);
                $video.src = breakpoints[nowBreakpoint].src;
            }
        }
        new VideoResponser("#background-video");
    }
   //Fancybox
    if($('a[data-fancybox="gallery"]').length){
        $('[data-fancybox]').fancybox({
            keyboard: false,
            infobar: false,
            buttons: [
                "close"
            ],
        });
    }
    //Mobile nav
    if($('.header__nav-open').length){
        // Open menu
        $('.header__nav-open').click(function (){
            $('.header__nav').addClass('active').fadeIn(500)
        })
        // Close menu
        $('.header__nav-close').click(function (){
            $('.header__nav').fadeOut(500,function (){
                $(this).removeClass('active')
            })
        })
    }
    if($('.header').length) {
        window.addEventListener('scroll',() =>{
            const stickyOffset = $('.header').height();
            const sticky = $('.header'),
                scroll = $(window).scrollTop();
            if (scroll >= stickyOffset) sticky.addClass('fixed');
            else sticky.removeClass('fixed');
        })
    }
    if($('body.light-background').length){
        $('.header__nav-open').addClass('black');
    }
});